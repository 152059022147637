import '../App.css';
import React from 'react';
import DivInformation from './Components/DivInformation';
import InfoCover from './Components/InfoCover';
export default class Information extends React.Component{
    constructor(){
        super();
        this.state = {
            tarjetas: [
                {
                    id: "data1",
                    subtitle: "¿Quiénes somos?",
                    info: "Somos una empresa especializada en el desarrollo de software a medida para brókers de seguros. Creamos soluciones tecnológicas que optimizan la gestión de clientes, cotizaciones y pólizas. \nNuestro enfoque está centrado en la seguridad, integración y escalabilidad. Contamos con un equipo de expertos en desarrollo y la industria aseguradora. Buscamos mejorar la competitividad y eficiencia operativa de nuestros clientes."
                },
                {
                    id: "data2",
                    subtitle: "Misión",
                    info: "Continuar en el crecimiento como empresa de soluciones tecnológicas de alto valor agregado para los mercados de Seguros, Financieros, Industriales y Comerciales. Priorizando la mejora continua de nuestros valores morales y profesionales que nos caracterizan, Permitido encontrar la satisfacción de nuestros clientes."
                },
                {
                    id: "data3",
                    subtitle: "Visión",
                    info: "Ser una empresa con presencia internacional en la industria del desarrollo de Software, inclinada al mercado de Seguros, Financiero, Industrial y Comercial, amparados en un equipo profesional y comprometido a largo plazo."
                },
                {
                    id: "data4",
                    subtitle: "Nuestros valores",
                    info: "• Vocación de servicios. \n• Excelencia y profesionalismo. \n• Integridad y ética profesional. \n• Confidencialidad y respeto por nuestros clientes. \n• Compromiso con las tareas y orgullo por realizarlas. \n• Innovación en todo lo que hacemos. \n• Trabajo en equipo. \n• Relaciones con proveedores y clientes basadas en el concepto “Ganar & Ganar”."
                }
            ]
        }
    }
    render(){
        return(
            <>
                <div className="container">
                    <section className="info_parteUno alejadoTop">
                        <div className="contenedor-texto">
                            <InfoCover/>
                        </div>
                    </section>
                    <section className="info_parteDos">
                        <div className="contenedor-texto">
                            <div className="container">
                                <ul>
                                    {this.state.tarjetas.map((tarjeta) => (
                                        <DivInformation 
                                        id={tarjeta.id}
                                        subtitle={tarjeta.subtitle}
                                            info={tarjeta.info.split('\n').map((linea, index) => (
                                                <span key={index}>
                                                    {linea}<br />
                                                </span>
                                            ))}
                                        />
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </section>
                </div>
            </>
        );
    }
}
