import React, { Component } from 'react';
import TefisoftPicture from '../../Images/Tittle.png';

export default class NavBar extends Component{
    render(){
        return(
            <nav className="navbar navbarGeneral navbar-expand-lg">
                <div className="container-fluid">
                    <a href='/'>
                        <img className="logoMenu" src={TefisoftPicture} alt="Tefisoft Logo"/>
                    </a>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                        <span className='whiteLetter'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-list" viewBox="0 0 16 16">
                            <path fill-rule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5"/>
                            </svg>
                        </span>
                    </button>
                    <div className="collapse navbar-collapse justify-content-end" id="navbarNavDropdown">
                        <ul className="navbar-nav">
                            <li className="nav-item">
                            <a className="nav-link whiteLetter" aria-current="page" href="/">Inicio</a>
                            </li>
                            <li className="nav-item">
                            <a className="nav-link whiteLetter" aria-current="page" href="../Information">Información</a>
                            </li>
                            <li className="nav-item">
                            <a className="nav-link whiteLetter" aria-current="page" href="../Services">Servicios</a>
                            </li>
                            <li className="nav-item dropdown">
                            <a className="nav-link dropdown-toggle whiteLetter" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                Productos
                            </a>
                            <ul className="dropdown-menu">
                                <li><a className="dropdown-item" aria-current="page" href="../EfiSeguros">EFISeguros</a></li>
                                <li><a className="dropdown-item" aria-current="page" href="../Facturacion">Facturación Electrónica</a></li>
                            </ul>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        );
    }
}
