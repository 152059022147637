import React from 'react';

const Squares = (props) => {
  return (
    <>
      <div className="col">
        <div className={"cuadros whiteLetter " + props.color}>
          <div className="card2 border">
            <div className="face front">
              <h1><i className={props.icon}></i></h1>
              <h4> { props.txt }</h4>
            </div> 
            <div className="face back d-flex align-items-center justify-content-center">
              <p className="text-center text-wrap">{ props.info }</p>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="col">
        <div className={"cuadros whiteLetter " + props.color}>
          <div className="card2 border">
            <div className="face front">
              <h1><i className={props.icon}></i></h1>
              <h4> { props.txt }</h4>
            </div>
            <div className="face back">
              <p>{ props.info }</p>
            </div>
          </div>
        </div>
      </div> */}
    </>
  )
}
export default Squares;