import React from 'react';

export default function Footer() {
    function WhatsApp(){
        window.location.href = 'https://wa.me/+593984735310?text=Hola! Me gustaría obtener más información sobre sus productos.';
    }
    function Mail(){
        window.open('mailto:luis.ona@tefisoft.com?subject=Información Tefisoft&body=Hola, me gustaría obtener más información sobre sus productos.');
    }
    function Phone(){
        window.location.href = 'tel:+593984735310';
    }
  return (
    <>
        <div className='b-example-divider'/><br/>
          <div className="footer">
              <br/>
            <div className="container">
                <footer className='py-1'>
                    <div className="row">
                        <div className="col-6 col-md-3 mb-3">
                            <h5><b>Contactos:</b></h5>
                            <div className="nav flex-column">
                                <div className="nav-item mb-2">
                                    <p className="whiteLetter p-0 ">Celular: <br/> (+593) 0991892002</p>
                                </div>
                                <div className="nav-item mb-2">
                                    <a className="whiteLetter p-0 ">comercial@tefisoft.com</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-6 col-md-3 mb-4">
                            <h5><b>TefiSoft Cia. Ltda.</b></h5>
                            <div className="nav flex-column justificado">
                                <div className="nav-item mb-2">
                                    <p className="whiteLetter p-0 ">Av. De las Palmeras N46-420 y de los madroños. <br/>Sector Norte – Los Laureles</p>
                                </div>
                                <div className="nav-item mb-2">
                                    <p className="whiteLetter p-0 ">Quito – Ecuador</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-5 offset-md-1 mb-3">
                            <form>
                                <h1>Contactanos:</h1>
                                <hr />
                                <div className="container">
                                    <div className="row row-cols-3 row-cols-lg-3">
                                        <div id='BtnWhatsapp' onClick={WhatsApp} className="col text-center">
                                            <h2><i className="fa fa-whatsapp"></i></h2>
                                            <p>Whatsapp</p>
                                        </div>
                                        <div className="col text-center" onClick={Mail}>
                                            <h2><i className="fa fa-envelope-o"></i></h2>
                                            <p>Correo</p>
                                        </div>
                                        <div className="col text-center" onClick={Phone}>
                                                <h2><i className="fa fa-phone"></i></h2>
                                                <p>Teléfono</p>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className='d-flex flex-column flex-sm-row justify-content-between py-4 my-4 border-top'>
                        <p>© EFISeguros. Todos los derechos reservados 2022</p>
                    </div>
                </footer>
            </div>
        </div>
    </>
  )
}
