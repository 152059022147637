import React from "react";
import Aos from 'aos';
import { useEffect } from "react";
import "aos/dist/aos.css";

const DivInformation = (props) => {
    useEffect(() => {
        Aos.init({duration: 2000});
    }, []);
    return(
        <>
        <div data-aos="fade-up">
            <li className="mb-2 row">
                        <button className="btnCollapse" data-bs-toggle="collapse" data-bs-target={"#"+props.id} aria-expanded="false" aria-controls="collapseExample">
                            <h1 className="Questions col-sm-5 float-start">{props.subtitle}</h1>
                            <div className="col-sm-3 float-end">
                                <h1>+</h1>
                            </div>
                        </button>
                    </li>
            <hr/>
            <div className="collapse" id={props.id}>
                <div className="card card-body cardBG justificado">
                {props.info}
                </div>
            </div>
        </div>
        </>
    );
};
export default DivInformation;